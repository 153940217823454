(function () {
    'use strict';

    angular.module('aerosApp')
        .config(['$stateProvider', '$urlRouterProvider', 'NotificationProvider', '$translateProvider',
            '$resourceProvider', 'routesConstant', function ($stateProvider, $urlRouterProvider, NotificationProvider,
                                                             $translateProvider, $resourceProvider, routesConstant) {

                var CUSTOMER = routesConstant.CUSTOMER;

                $resourceProvider.defaults.stripTrailingSlashes = false;

                $stateProvider
                    .state(CUSTOMER.DASHBOARD.stateName, {
                        url: CUSTOMER.DASHBOARD.url,
                        templateUrl: '/static/templates/dashboard/customerDashboard.html',
                        controller: 'CustomerDashboardController',
                        resolve: {
                            allPermissions: ['allPermissions', function (allPermissions) {
                                return allPermissions.getAllPermissions();
                            }]
                        },
                        redirect: {
                            url: CUSTOMER.PROJECTS.stateName
                        }
                    })
                    .state(CUSTOMER.PROJECTS.stateName, {
                        url: CUSTOMER.PROJECTS.url,
                        views: {
                            "main": {
                                controller: 'ProjectDashboardCtrl',
                                templateUrl: '/static/templates/project/projects-dashboard.html'
                            }
                        }
                    })
                    .state(CUSTOMER.PROJECTS_PROJECT.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT.url,
                        abstract: true,
                        views: {
                            "main": {controller: 'ProjectCtrl', templateUrl: '/static/templates/project/project.html'}
                        },
                        resolve: {
                            project: ['$state', '$stateParams', 'aerosApi', '$rootScope', 'SessionService', 'ProjectService',
                                function ($state, $stateParams, aerosApi, $rootScope, SessionService, ProjectService) {
                                    var sessionInfo = SessionService.getSessionInfo();

                                    var projectId = $stateParams.id || $state.params.id;

                                    return ProjectService.fetchProject(projectId,
                                        sessionInfo.organization.id)
                                        .then(function (rtn) {
                                            if (!rtn.data) {
                                                return $rootScope.currentProject = {};
                                            }

                                            return $rootScope.currentProject = rtn.data;
                                        });

                                }],
                            allRoles: ['allRoles', function(allRoles) {
                                return allRoles.getAllRoles();
                            }]
                        }
                    })
                    .state(CUSTOMER.PROJECTS_PROJECT_FIBERGROUPS.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT_FIBERGROUPS.url,
                        views: {
                            "content": {
                                controller: 'FiberGroupCtrl',
                                templateUrl: '/static/templates/project/fibergroups-tab.html'
                            }
                        },
                        resolve: {
                            toolsDefinition: ['ToolService', function (ToolService) {
                                return ToolService.getToolsDefinition();
                            }],
                            simpleFiberTypes: ['FiberGroupService', function (FiberGroupService) {
                                return FiberGroupService.getSimpleFiberTypes();
                            }],
                            project: ['project', function (project) {
                                return project;
                            }]
                        }
                    })
                    .state(CUSTOMER.PROJECTS_PROJECT_TEAM.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT_TEAM.url,
                        views: {
                            "content": {templateUrl: '/static/templates/project/team-tab.html'}
                        }
                    })
                    .state(CUSTOMER.PROJECTS_PROJECT_IMPORT.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT_IMPORT.url,
                        views: {
                            "content": {
                                controller: 'ProjectSorImportController',
                                templateUrl: '/static/templates/project/import-tab.html'
                            }
                        }
                    })
                    .state(CUSTOMER.PROJECTS_PROJECT_RESULTS.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT_RESULTS.url,
                        resolve: {
                            projectSummary: ['$stateParams', 'aerosApi', 'SessionService', function ($stateParams, aerosApi, SessionService) {
                                if ($stateParams.testType != 'summary') { // summary resolves this in controller - long running api call
                                    var sessionInfo = SessionService.getSessionInfo();

                                    if (sessionInfo && sessionInfo.organization) {
                                        return aerosApi.loadProjectSummary($stateParams.id, sessionInfo.organization.id)
                                            .then(function (rtn) {
                                                return rtn.data;
                                            });
                                    }

                                }

                            }],
                            tools: function () {
                                return ["OLTS", "Inspection", "OTDR", "Certification"]
                            }
                        },
                        views: {
                            "content": {
                                controllerProvider: function ($stateParams) {
                                    return $stateParams.testType + 'ResultsCtrl';
                                },
                                templateUrl: function ($stateParams) {
                                    return '/static/templates/project/results/' + $stateParams.testType + 'ResultsView.html';
                                }
                            }
                        },
                        params: {
                            linkmap: 0
                        }
                    })
                    .state(CUSTOMER.PROJECTS_PROJECT_RESULTS_ALL.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT_RESULTS_ALL.url,
                        resolve:{
                            project: ['project', function(project) {
                                return project;
                            }]
                        },
                        views: ["OLTS", "Inspection", "OTDR", "Certification"].reduce(function (acc, tool) {
                            acc[tool] = {
                                controllerProvider: function () {
                                    return tool + 'ResultsCtrl';
                                },
                                templateUrl: function () {
                                    return '/static/templates/project/results/' + tool + 'ResultsView.html';
                                }
                            };

                            return acc;
                        }, {})
                    })
                    .state(CUSTOMER.PROJECTS_PROJECT_TRACEVIEWER.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT_TRACEVIEWER.url,
                        views: {
                            "content": {
                                controller: "TraceViewer",
                                templateUrl: '/static/templates/project/results/OTDRTraceViewerView.html'
                            }
                        }
                    })
                    // known testTypes: summary, fibergroup, OLTS, Certication, Inspection, OTDR

                    .state(CUSTOMER.PROJECTS_PROJECT_CONTACTS.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT_CONTACTS.url,
                        views: {
                            "content": {
                                controller: 'ProjectContactsCtrl',
                                templateUrl: '/static/templates/project/projectContacts-tab.html'
                            }
                        }
                    })
                    .state(CUSTOMER.PROJECTS_PROJECT_REPORT.stateName, {
                        url: CUSTOMER.PROJECTS_PROJECT_REPORT.url,
                        views: {
                            "content": {
                                controller: 'ReportCtrl',
                                templateUrl: '/static/templates/project/report-tab.html'
                            }
                        },
                        resolve: {
                            project: ['project', function(project) {
                                return project;
                            }]
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION.stateName, {
                        url: CUSTOMER.ORGANIZATION.url,
                        abstract: true,
                        views: {
                            "main": {
                                template: '<div ui-view="org_wrapper"></div>'
                            }
                        },
                        resolve: {
                            allRoles: ['allRoles', function (allRoles) {
                                return allRoles.getAllRoles();
                            }]
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION_PROFILE.stateName, {
                        url: CUSTOMER.ORGANIZATION_PROFILE.url,
                        views: {
                            "org_wrapper": {
                                controller: 'OrgProfileCtrl',
                                templateUrl: '/static/templates/org/orgProfile.html'
                            }
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION_USERS.stateName, {
                        url: CUSTOMER.ORGANIZATION_USERS.url,
                        views: {
                            "org_wrapper": {controller: 'UsersCtrl', templateUrl: '/static/templates/org/users/users.html'}
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION_CONTACTS.stateName, {
                        url: CUSTOMER.ORGANIZATION_CONTACTS.url,
                        views: {
                            "org_wrapper": {controller: 'ContactsCtrl', templateUrl: '/static/templates/org/orgContacts.html'}
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION_TESTSETUPS.stateName, {
                        url: CUSTOMER.ORGANIZATION_TESTSETUPS.url,
                        views: {
                            "org_wrapper": {
                                controller: 'TestSetupCtrl',
                                templateUrl: '/static/templates/org/testsetups/test-setups.html'
                            }
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION_TESTLIMITS.stateName, {
                        url: CUSTOMER.ORGANIZATION_TESTLIMITS.url,
                        views: {
                            "org_wrapper": {
                                controller: 'TestLimitsCtrl',
                                templateUrl: '/static/templates/org/testlimits/test-limits.html'
                            }
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION_TOOLS.stateName, {
                        url: CUSTOMER.ORGANIZATION_TOOLS.url,
                        redirect: {
                            url: CUSTOMER.ORGANIZATION_TOOLS_TYPE_TOOLLIBRARIES.stateName,
                            params: {
                                toolType: 'All'
                            }
                        },
                        views: {
                            "org_wrapper": {
                                controller: 'BasicToolController as tc',
                                templateUrl: '/static/templates/tools/basic.html'
                            }
                        },
                        resolve: {
                            userInfo: ['aerosApi', '$stateParams', function (aerosApi, $stateParams) {
                                return aerosApi.loadOrganizationUserInfo($stateParams.id).then(function (d) {
                                    return d.data
                                });
                            }]
                        }
                    })
                    .state(CUSTOMER.LINKORG.stateName, {
                        url: CUSTOMER.LINKORG.url,
                        views: {
                            "main": {
                                controller: 'LinkDashboardController',
                                templateUrl: '/static/templates/link/linkDashboard.html',
                                params: {
                                    linkRole: 'link'
                                }
                            }
                        }
                        /* This code is commented out because of dependencies to in AD-40
                        and for sprint 1, the scope of AD-40 was simplified;
                        Marketing is planning to use the original scope of AD-40 in print 3 or 4.
                        For that reason, I didn't remove this route, but commented it out

                        url: CUSTOMER.ORGANIZATION_LINK.url,
                        controller: 'dashboardController',
                        templateUrl: '/static/templates/link/dashboard.html',
                        params: {
                            linkRole: 'link'
                        }*/
                    })
                    .state(CUSTOMER.ORGANIZATION_TOOLS_TYPE.stateName, {
                        url: CUSTOMER.ORGANIZATION_TOOLS_TYPE.url,
                        views: {
                            "org_content": {
                                controller: 'ToolTypeController as toolTypeCtrl',
                                templateUrl: '/static/templates/tools/group.html'
                            }
                        },
                        resolve: {
                            toolsDefinition: ['ToolsDefinitionService', function (defSrvc) {
                                return defSrvc.fetch();
                            }]
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION_TOOLS_TYPE_TOOLLIBRARIES.stateName, {
                        url: CUSTOMER.ORGANIZATION_TOOLS_TYPE_TOOLLIBRARIES.url,
                        views:{
                            "org_details": {
                                template: `<afl-tool-grid 
                                                license="toolLibsCtrl.userInfo.organization.license"
                                                cols="toolLibsCtrl.cols"
                                                rows="toolLibsCtrl.rows"
                                                widths="toolLibsCtrl.widths"
                                                on-reload="toolLibsCtrl.reload()"></afl-tool-grid>`,
                                controller: 'ToolLibrariesController as toolLibsCtrl'
                            }
                        },
                        resolve: {
                            userInfo: ['aerosApi', '$stateParams', function (aerosApi, $stateParams) {
                                return aerosApi.loadOrganizationUserInfo($stateParams.id).then(function (d) {
                                    return d.data
                                });
                            }],
                            toolPresets: ['PresetService', '$stateParams', function (PresetService, $stateParams) {
                                return PresetService($stateParams.toolType).fetch($stateParams.id);
                            }],
                            toolLibraries: ['$stateParams', 'ToolLibFactory', function ($stateParams, ToolLibFactory) {
                                return ToolLibFactory($stateParams.toolType).query({orgId: $stateParams.id}).$promise;
                            }],
                            toolsDefinition: ['ToolsDefinitionService', function (defSrvc) {
                                return defSrvc.fetch();
                            }]
                        }
                    })
                    .state(CUSTOMER.ORGANIZATION_TOOLS_TYPE_PRESETS.stateName, {
                        url: CUSTOMER.ORGANIZATION_TOOLS_TYPE_PRESETS.url,
                        views: {
                            "org_details": {
                                template: `<afl-tool-preset-grid 
                                                license="toolPresCtrl.userInfo.organization.license"
                                                presets="toolPresCtrl.presets"
                                                widths="toolPresCtrl.widths" 
                                                on-reload="toolPresCtrl.reload()"></afl-tool-preset-grid>`,
                                controller: 'ToolPresetsController as toolPresCtrl'
                            }
                        },
                        resolve: {
                            userInfo: ['aerosApi', '$stateParams', function (aerosApi, $stateParams) {
                                return aerosApi.loadOrganizationUserInfo($stateParams.id).then(function (d) {
                                    return d.data
                                });
                            }],
                            toolPresets: ['PresetService', '$stateParams', function (PresetService, $stateParams) {
                                return PresetService($stateParams.toolType).fetch($stateParams.id);
                            }]
                        }
                    });

                NotificationProvider.setOptions({
                    positionY: 'bottom'
                });

                var preferredLanguage = (navigator.languages ? navigator.languages[0] : navigator.language);
                $translateProvider
                    .useStaticFilesLoader({
                        prefix: '/i18n/',
                        suffix: '.json'
                    })
                    .preferredLanguage(preferredLanguage)
                    .fallbackLanguage('en-US');
            }]);
}());
